import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Projects" />
    <h2>Research Projects</h2>
    <h4>AlgoSolve: Supporting Subgoal Learning in Algorithmic Problem-Solving with Learnersourced Microtasks</h4>
    <p>
      Designing solution plans before writing code is critical for successful algorithmic problem-solving. Novices, however, often plan on-the-fly during implementation, resulting in unsuccessful problem-solving due to lack of mental organization of the solution. Research shows that subgoal learning helps learners develop more complete solution plans by enhancing their understanding of the high-level solution structure. However, expert-created materials such as subgoal labels are necessary to provide learning benefits from subgoal learning, which are a scarce resource in self-learning due to limited availability and high cost. We propose a learnersourcing workflow that collects high-quality subgoal labels from learners by helping them improve their label quality. We implemented the workflow into AlgoSolve, a prototype interface that supports subgoal learning for algorithmic problems. A between-subjects study with 63 problem-solving novices revealed that AlgoSolve helped learners create higher-quality labels and more complete solution plans, compared to a baseline method known to be effective in subgoal learning.
    </p>
    <p>
      Research project at <a href="https://www.kixlab.org/">KIXLAB</a>, to appear at <a href="https://chi2022.acm.org/">CHI 2022</a>.
    </p>
    <h4>AlgoPlan: Supporting Planning in Algorithmic Problem-Solving with Subgoal Diagrams</h4>
    <p>
      Planning a solution before writing code is essential in algorithmic problem-solving. However, novices often skip planning and jump straight into coding. Even if they set up a plan, some do not connect to their plan when writing code. Learners solving algorithmic problems often struggle with high-level components such as solution techniques and sub-problems, but existing representations that guide learners in planning, such as flowcharts, focus on presenting lower-level details. We use subgoal diagrams – diagrams made of subgoal labels and the relationships between them – as a representation that guides learners to focus on high-level plans when they develop solutions. We introduce AlgoPlan, an interface that enables learners to build their own subgoal diagram and use it to guide their problem-solving process. A preliminary study with seven students shows that subgoal diagrams help learners focus on high-level plans and connect these plans to their code.
    </p>
    <p>
    Research project at <a href="https://www.kixlab.org/">KIXLAB</a>, presented at <a href="https://learningatscale.acm.org/las2020/">L@S 2020</a>. (<a href="https://kixlab.github.io/website-files/2020/l@s2020-AlgoPlan-poster.pdf">paper</a>)
    </p>
    <p>
      Tech stack: React,TypeScript, SCSS, Django, Django REST Framework
    </p>
    <h4>SolveDeep: supporting subgoal learning in math problem solving</h4>
    <p>
      Subgoal learning helps learners understand generalizable problem solving strategies by guiding them through the hierarchical solution structure. Subgoal learning is effective when learners construct their own subgoal labels, but is challenging at the same time as learners struggle to generate quality subgoal labels without proper guidance. <a href="https://jhw123.github.io/solvedeep/solvedeep.html">Prior work</a> has shown the potential of an online learning system that supports subgoal learning for math problems. We aim to further enhance the learning experience with the help of instructors, where instructors can also benefit from reviewing diverse solution approaches taken by learners.
    </p>
    <p>
      Research project at <a href="https://www.kixlab.org/">KIXLAB</a>.
    </p>
    <h4>Learning fault localisation for both humans and machines using multi-objective GP</h4>
    <p>
      Genetic Programming has been successfully applied to fault localisation to learn ranking models that place the faulty program element as near the top as possible. However, it is also known that, when localisation results are used by Automatic Program Repair (APR) techniques, higher rankings of faulty program elements do not necessarily result in better repair effectiveness. Since APR techniques tend to use localisation scores as weights for program mutation, lower scores for nonfaulty program elements are as important as high scores for faulty program elements. We formulate a multi-objective version of GP based fault localisation to learn ranking models that not only aim to place the faulty program element higher in the ranking, but also aim to assign as low scores as possible to non-faulty program elements. The results show minor improvements in the suspiciousness score distribution. However, surprisingly, the multi-objective formulation also results in more accurate fault localisation ranking-wise, placing 155 out of 386 faulty methods at the top, compared to 135 placed at the top by the single objective formulation.
    </p>
    <p>
      Research project at <a href="https://coinse.kaist.ac.kr/">COINSE lab</a>, presented at <a href="http://ssbse18.irisa.fr/">SSBSE 2018</a>. (<a href="https://coinse.kaist.ac.kr/publications/pdfs/Choi2018aa.pdf">paper</a>)
    </p>
    <h2>Toy Projects</h2>
    <h4>RecBook: Record your book reading history</h4>
    <p>
      A website for recording booknotes and reading progress. (<a href="https://recbook.netlify.app/">website</a>)
    </p>
    <p>
      Tech stack: React, TypeScript, SCSS, Django, GraphQL - Apollo (client), Graphene (server)
    </p>
    <h2>Course Projects</h2>
    <h4>BiasExplorer: exploring biased labels in crowdsourced datasets</h4>
    <p>
      Crowdsourcing is a popular way to collect large-scale datasets. However, these datasets might contain biases depending on the workers' performance. We designed an interface where users can explore the dataset and examine whether the dataset contains biases.
    </p>
    <p>
      Computer Ethics and Social Issues course project (Fall 2019) | <a href="https://www.dropbox.com/s/hj8xreq3u90a8ze/Team%202%20Final%20Presentation.pdf?dl=0">final presentation slides</a>
    </p>
    <h4>Goalie: become better at designing solutions by learning from others</h4>
    <p>
      Planning out a solution before jumping into code is important in algorithmic problem solving, but there is a lack of guidlines and instructor resources for supporting such practice. Most platforms for algorithmic problem solving do not support solution planning. Goalie is a platform where learners can design their solutions in the form of subgoals and improve them by learning from examples generated by peers. Quality of the examples gets gradually improved as more learners participate in the platform, providing a rich learning experience.
    </p>
    <p>
      Introduction to Social Computing course project (Fall 2019) | <a href="https://goalie-web.firebaseapp.com/">live interface</a> | <a href="https://www.dropbox.com/s/nawcr8dodjdjl2p/JHK_Final.pdf?dl=0">final presentation slides</a>
    </p>
    <h4>Dynamic branch history filtering by probabilistic classification</h4>
    <p>
      Branch predictor (BP) is an essential componenet in modern processors. Branch history-based BP is a popular approach, where state-of-the-art predictors benefit from long branch history. We aim to reduce storage inefficiency from the long branch history by probabilistically classifying biased branches.
    </p>
    <p>
      Computer Architecture course project (Spring 2019) | <a href="https://www.dropbox.com/s/x4nzvhz6lsx629b/Final%20Presentation.pdf?dl=0">final presentation slides</a>
    </p>
    <h4>EFG: automated ecofont generator</h4>
    <p>
      Ecofonts are fonts that reduce ink consumption while looking the same as normal fonts. However, ecofonts require manual labor, which is unreasonable if the character set is huge (e.g., Korean). EFG is an automated ecofont generator which can convert any fonts into ecofonts, which takes around 10 minutes for English fonts. User test results indicate that the generated ecofonts are even better than manually-designed ones.
    </p>
    <p>
      Computer Science Project course project (Fall 2018) | <a href="https://www.dropbox.com/s/eft252mc519bowi/ABCD_EFG_EOSP_SLIDE.pdf?dl=0">final presentation slides</a>
    </p>
    <h4>DdanJit</h4>
    <p>
      Ddanjit is a simple 2-player game, motivated by a traditional Korean game.
    </p>
    <p>
      Introduction to Computer Game Technology course project (Fall 2018) | <a href="https://www.dropbox.com/s/lanhegpsna35qpu/CTP321%20final%20.pdf?dl=0">final presentation slides</a>
      </p>
    <h4>GUI test suite generation using Ant Colony Optimization with EFG traversal criterion</h4>
    <p>
      Artificial Intelligence Based Software Engineering course project (Fall 2017) | <a href="https://www.dropbox.com/s/hze4a3crm14g82n/CS454_FInalPres_171129_23.pdf?dl=0">final presentation slides</a>
    </p>
    <h4>DDD: don't drink and drive</h4>
    <p>
      DDD is a serious game that shows the dangers of drunk driving.
    </p>
    <p>
      Games, Society, and Culture course project (Spring 2016)
    </p>
    </Layout>
)

export default IndexPage
